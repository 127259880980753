#highlight {
  background: #00B4D1;
  color: #FFF;
  width: 100vw;
  position: relative;
  margin: -1.5rem 0 1.5rem -50vw;
  left: 50%;
  padding: 1rem;
  text-align: center;
  font-size: .9rem;
  line-height: 1.3rem;
  a {
    color: #FFF;
    &:hover {
      color: #c6eaef;
    }
  }
  img {
    width: auto;
  }
}

@media (min-width: 768px) {
  #highlight {
    text-align: left;

    > .container {
      padding-left: 212px;
    }

    p:first-child {
      margin: 20px 0 0 -192px;
      position: absolute;
    }
  }
}

#highlight {
  p, h1 {
    margin: 0 0 .5rem;
  }
}

#highlight img {
  display: block;
  border-radius: 50%;
  border: 1px solid #E0E0E0;
  margin: 0 auto;
}
