#photos {
  .photo {
    padding-bottom: 5em;
    img {
      max-height: 90vh;
      object-fit: cover;
    }
  }
}
#photo {
  font-size: .7rem;

  .location {
    color: #999
  }
}
