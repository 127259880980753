#page-about h1 {
  text-align: center;
  padding: 18px 0 36px;
}

#page-about img {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #E0E0E0;
  width: auto;
}
