html, body {
  font: normal 20px/1.6em -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
  color: #00B4D1;
}
a:hover, a:focus {
  color: #00899e;
}

p {
  margin: 0 0 1.5rem;
}

img {
  width: 100%;
  height: auto;
}

article {
  clear: both;
  padding: 0;
}

.small {
  font-size: 80%;
}

h1 {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0 0 1.5rem;

  small {
    color: #828282;
    font-size: .7rem;
    display: block;
  }
}

h2 {
  font-weight: bold;
  font-size: 1em;
}

@media (min-width: 960px) {
  .container {
    width: 38rem;
    margin: 0 auto;
  }
}


header {
  border-bottom: 1px solid #E0E0E0;
  padding: .5rem;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }

  #logo {
    font-size: 1.2rem;
  }

  a {
    text-decoration: none;
  }
  nav {
    display: flex;
    a {
      font-size: .9rem;
      padding: 0 .5rem;
    }
  }
}

main {
  padding: 1.5rem;
}

footer {
  border-top: 1px solid #E0E0E0;
  color: #444;
  text-align: center;
  padding: 1rem 1rem 0;
  nav {
    font-size: .8rem;
    text-align: center;
    a {
      display: inline-block;
      padding: 4px 12px;
    }
  }
  p {
    margin: 1.5rem 0;
    font-size: .7rem;
  }
  span.secondary {
    color: #999;
  }
}


#posts {
  li {
    padding-bottom: 1rem;
    width: 100%;
    @media (min-width: 720px) {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-bottom: .5rem;
    }
  }

  time {
    color: #828282;
    display: block;
    font-size: .8rem;
    line-height: .8rem;
    @media (min-width: 720px) {
      color: #a1a1a1;
      font-size: 1rem;
      line-height: 1rem;
    }


  }
}

.post-meta {
  color: #828282;
  font-size: .8rem;
  margin: 0;
}


/**
 * Code formatting
 */
pre,
code {
  font-size: .9rem;
  font-family: Menlo, "Lucida Console", monospace;
  background-color: #FAFAFA;
}

code {
  padding: 1px 5px;
  border: 1px solid #E0E0E0;
}

pre {
  padding: 1rem .75rem;
  margin: 0 0 1.5rem;
  overflow-x: auto;
  line-height: 1.6em;
  border: 0;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
